import React from "react";
import "./faq.css";

const faqData = {
  "platform-overview": [
    {
      question: "What is Krizaar?",
      answer:
        "Krizaar is a multivendor e-commerce platform that integrates Web2 and Web3 technologies, enabling sellers to list products and sell them globally using both traditional and blockchain-based payment options.",
    },
    {
      question: "What makes Krizaar different from other e-commerce platforms?",
      answer:
        "Krizaar uniquely integrates Web2 and Web3 technologies, offering decentralized payments and logistics, and providing tokenized identity verification (KYC/KYB) for enhanced security.",
    },
    {
      question: "What kind of products can I sell on Krizaar?",
      answer:
        "Krizaar allows sellers to list a wide range of products, including electronics, fashion, household items, and digital goods, as long as they comply with listing policies and local regulations.",
    },
  ],
  "seller-onboarding": [
    {
      question: "How do sellers onboard onto Krizaar?",
      answer:
        "Sellers can onboard by completing KYC (Know Your Customer) and KYB (Know Your Business) verification processes. Krizaar uses blockchain to securely store and tokenize these attributes.",
    },
    {
      question: "What support is available for sellers on Krizaar?",
      answer:
        "Krizaar provides dedicated support for sellers, assisting with onboarding, product listing optimization, logistics, and payments. Sellers can also access help from the platform’s support team.",
    },
  ],
  "international-selling": [
    {
      question: "Can I sell internationally on Krizaar?",
      answer:
        "Yes, sellers on Krizaar can sell to both North American and Asian consumers. They can choose to manage their own fulfillment or use Krizaar Logistics for shipping and handling.",
    },
  ],
  payments: [
    {
      question: "How does Krizaar handle payments?",
      answer:
        "Krizaar supports multiple payment options, including credit card payments, traditional fiat remittances, and blockchain-based payouts, such as USDC.",
    },
    {
      question: "Can I use traditional payment methods on Krizaar?",
      answer:
        "Yes, Krizaar allows consumers to pay using credit and debit cards, in addition to blockchain-powered payments like USDC.",
    },
    {
      question: "How will I receive my refund?",
      answer:
        "Refunds are credited back to the original payment method. If you paid with cryptocurrency or USDC, the refund will be directed to the original wallet.",
    },
    {
      question: "How does Krizaar handle refunds for international purchases?",
      answer:
        "For international purchases, the return process may take longer due to shipping times, but refunds will be processed within 5-10 business days after the return is approved.",
    },
  ],
  "shipping-logistics": [
    {
      question: "What are the shipping options available on Krizaar?",
      answer:
        "Sellers can either fulfill orders themselves or use Krizaar Logistics, which handles shipping, including end-to-end logistics services like freight forwarding.",
    },
    {
      question: "Who pays for the return shipping?",
      answer:
        "Return shipping costs depend on the seller’s policy. Some sellers offer free returns, while others require the buyer to cover the return shipping expenses.",
    },
  ],
  security: [
    {
      question: "How secure is Krizaar?",
      answer:
        "Krizaar uses blockchain technology to ensure secure transactions, data privacy, and tokenized KYC/KYB processes, with Digipass tokens used for enhanced verification.",
    },
    {
      question: "What is the role of blockchain in Krizaar?",
      answer:
        "Blockchain is used for secure identity verification (KYC/KYB), payments, and treasury payouts, adding transparency and security to transactions and data storage.",
    },
  ],
  "return-pickup": [
    {
      question: "What is Krizaar's return policy?",
      answer:
        "Buyers can return eligible products within the return window set by the seller, typically ranging from 7 to 30 days depending on the product category.",
    },
    {
      question: "How do I initiate a return?",
      answer:
        "To initiate a return, log in to your Krizaar account, go to your order history, and follow the steps to select the item and reason for return.",
    },
    {
      question: "What are the conditions for returning an item?",
      answer:
        "The product must be in its original condition, unused, with all packaging, tags, and accessories. Perishable goods and digital products may not be eligible for return.",
    },
    {
      question: "What items are non-returnable?",
      answer:
        "Non-returnable items include perishable goods, digital products, custom-made items, and products marked as 'final sale'.",
    },
    {
      question: "What if the product I received is damaged or defective?",
      answer:
        "You can request a return or replacement by contacting the seller with details and photos of the damage through your Krizaar account.",
    },
    {
      question: "Can I exchange an item instead of returning it?",
      answer:
        "Exchanges depend on the seller’s policy. Some sellers may allow exchanges for different sizes or colors. If not, you can return the item for a refund and place a new order.",
    },
  ],
  "refund-related": [
    {
      question: "How long does it take to process my return?",
      answer:
        "Once the returned product is received and inspected, it takes 3-5 business days for the return to be processed. You’ll receive a notification once it’s approved.",
    },
    {
      question: "When will I receive my refund?",
      answer:
        "Refunds are typically issued within 5-10 business days after the return is approved, depending on the payment method used.",
    },
    {
      question: "What if my return request is rejected?",
      answer:
        "If your return request is rejected, you will be notified of the reason. You can contact Krizaar’s support team for further assistance if needed.",
    },
  ],
  "order-tracking": [
    {
      question: "How can I track my orders on Krizaar?",
      answer:
        "You can track your order status by logging into your Krizaar account or checking the email notifications for real-time updates.",
    },
    {
      question: "How can I track my return status?",
      answer:
        "You can track the return status by logging into your Krizaar account and viewing your order history for updates such as 'return initiated' and 'return approved'.",
    },
  ],
  "order-cancellation": [
    {
      question: "Can I cancel my order before it ships?",
      answer:
        "Yes, you can cancel your order before it has been shipped by logging into your Krizaar account and selecting the option to cancel. Once shipped, you’ll need to follow the return process.",
    },
  ],
};

const FAQContent = ({ selectedCategory }) => {
  const content = faqData[selectedCategory];

  return (
    <div className="faq-content">
      {content?.map((item, index) => (
        <>
          <div key={index} className="faq-item">
            <h2>{item.question}</h2>
            <p>{item.answer}</p>
          </div>
          {/* <div className="feedback">
            <p>Was this helpful?</p>
            <button>Yes</button>
            <button>No</button>
          </div> */}
        </>
      ))
      }
    </div>
  );
};

export default FAQContent;
