import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';

const About = () => {

  const partnersLogos = [
    { name: "UPS", logo: "/images/about logos/ups.png" },
    { name: "USPS", logo: "/images/about logos/usps.png" },
    { name: "Uber Freight", logo: "/images/about logos/uber-frieght.png" },
    { name: "Shipjoy", logo: "/images/about logos/shipjoy.png" },
    { name: "Ship Edge", logo: "/images/about logos/ship-edge.png" },
    { name: "Google Circle", logo: "/images/about logos/google-circle.png" },
    { name: "Stripe", logo: "/images/about logos/stripe.png" },
    { name: "Paypal", logo: "/images/about logos/paypal.png" },
    { name: "NMI", logo: "/images/about logos/nmi.png" },
    { name: "Meta", logo: "/images/about logos/meta.png" },
    { name: "Thirdweb", logo: "/images/about logos/thirdweb.png" },
    { name: "1inch", logo: "/images/about logos/1inch.png" },
    { name: "AWS", logo: "/images/about logos/aws.png" },
    { name: "Microsoft", logo: "/images/about logos/microsoft.png" },
    { name: "Spreedly", logo: "/images/about logos/spreedly.png" },
    { name: "Shuffle", logo: "/images/about logos/shuffle.png" },
    { name: "AIBC Foundation Inc", logo: "/images/about logos/aibc.png" },
    { name: "DHL", logo: "/images/about logos/dhl.png" },
    { name: "Coinbase", logo: "/images/about logos/coinbase.png" },
    { name: "Base Chain", logo: "/images/about logos/base-chain.png" }
  ];
  return (
    <>
      <div className="container mt-5">
        <div className='terms-of-service mt-5'>
          <h1 className="about-heading">About Us </h1>
          <div className="row mt-5">
            <div className="col-lg-6">
              <h3 className="my-3">
                Shop Smart, Shop Secure
              </h3>
              <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                <div className=' d-flex gap-4'>
                  <div className='d-flex flex-column justify-content-center align-items-start'>
                    <div className=' d-flex gap-3 mt-1'>
                      <div style={{ textAlign: 'justify' }}>As global demand for e-commerce continues to grow, so does the need to meet this demand in a way that benefits everyone. The world is becoming more centralized, with global trade and commerce increasingly concentrated in the hands of a few. The threat posed by this centralization has never been more imminent than it is now.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                <div className=' d-flex gap-4'>
                  <div className='d-flex flex-column justify-content-center align-items-start'>
                    <div className=' d-flex gap-3 mt-1'>
                      <div style={{ textAlign: 'justify' }}>Krizaar is the world’s first decentralized e-commerce global marketplace. Our ecosystem is entirely built on blockchain technology, enabling transparency, data privacy, decentralization, accountability, and immutability.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/images/about1.jpg" alt="aboutus" className="about-img img-fluid" />
            </div>
          </div>

          <div className="row my-5">
            <div className="col-lg-6">
              <img src="/images/about2.jpg" alt="aboutus" className="about-img img-fluid" />
            </div>
            <div className="col-lg-6">
              <h3 className="my-3">
                And the best part?
              </h3>
              <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                <div className=' d-flex gap-4'>
                  <div className='d-flex flex-column justify-content-center align-items-start'>
                    <div className=' d-flex gap-3 mt-1'>
                      <div style={{ textAlign: 'justify' }}>
                        <>Our services span e-commerce marketplaces, drop shipping, social networks, in-house logistics management, and payments. We understand that to combat the growing threat of global inflation, we need a supply chain that can eliminate unnecessary intermediaries. This disintermediation and decentralization are made possible through the adoption of blockchain technology. </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                <div className=' d-flex gap-4'>
                  <div className='d-flex flex-column justify-content-center align-items-start'>
                    <div className=' d-flex gap-3 mt-1'>
                      <div style={{ textAlign: 'justify' }}>
                        <>We are proud to be the torchbearers of a new global era at this crucial juncture.  </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <h5>Ready to explore e-commerce like never before? </h5>
            <p>
              Sign up today and experience the most transparent buyer-seller
              bond. Your future self will thank you!
            </p>
          </div>
          {/* <div className="row my-5">
            <div className="col-lg-3">
              <div className="about-sellbox">

              </div>
            </div>
          </div> */}
        </div>
      </div >
      <div className="partners mb-5">
      <div className="container">
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          className="pb-5"
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
        >
          {partnersLogos.map((logo, index) => (
            <SwiperSlide key={index}>
              <div className=" d-flex justify-content-center" >

                <img src={logo.logo} alt={logo.name} className="partner-logo img-fluid" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
    </>
  );
};

export default About;
