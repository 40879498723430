import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { REACT_BASE_PATH, Asset_url } from "../api";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { FaEye, FaHeart, FaRegHeart, FaShoppingCart, FaStar } from 'react-icons/fa';
import { CartContext } from "../components/WebPanel/cart/CartContext";

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { toast } from "react-toastify";


const Productslide = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [wishlist, setWishlist] = useState(new Set())

  const { state, dispatch } = useContext(CartContext);


  const onSingleProduct = (productId) => {
    navigate(`/singleproduct/${productId}`);
  };

  const handleAddToCart = async (product) => {

    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      toast.warning("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      toast.success("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          toast.success("Product added to the cart!");
        } else {
          toast.error("Failed to add product to the cart");
        }
      } catch (error) {
        console.error("Error adding product to the cart:", error);
              toast.error(error.response?.data?.message || "Some error occured, please try again");

      }
    }
  }

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          toast.success("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
          return;
        } else {
          toast.error("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        console.error("Error removing product from wishlist:", error);
              toast.error(error.response?.data?.message || "Some error occured, please try again");

        return;
      }
    }

    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(productId));
      } else {
        toast.error("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
            toast.error(error.response?.data?.message || "Some error occured, please try again");

    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/getallproductsdetails`,
          {
            page: 1,
            pagesize: 10,
          }
        );
        if (response.data.data) {
          // console.log(response.data.data)
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };

    fetchProducts();
    fetchWishlist();
  }, []);

  const renderProductRow = (productRow) => (
    <div className="container">

      <div className="row mt-4">
        {productRow.map((product) => (
          <div key={product._id} className="col-lg-3 prod-box my-3" onClick={() => onSingleProduct(product._id)}>
            <div className="product-box-1">
              <img
                src={product.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`}
                alt={product.product_name}
                className="prod-img img-fluid"
              />
              <span >
                <p className="product-title">{product.product_name.length>60 ? product.product_name.slice(0,50) + '.....' : product.product_name}</p>
              </span>

              <span>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </span>
              <p className="rate">
                {product.offer_price ? `$${product.offer_price}` : product.price ? `$${product.price}` : "Price not available"}
              </p>
              <div className="icons">
                <div className="cart-icon" onClick={(e) => {
                  // e.preventDefault();
                  // e.stopPropagation();
                  // handleAddToCart(product);
                }}>
                  <FaShoppingCart />
                </div>
                <div className="wishlist-icon" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAddToWishlist(product._id);
                }}>
                  {
                    wishlist.has(product._id) ?
                      <FaHeart /> : <FaRegHeart />
                  }
                </div>
                <div className="eye-icon" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                  <FaEye />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <section>
        <div className="container-fluid prod-section">
          <div className="row">
            <div className="heading-box">
              <span>
                <h3 className="prod-heading">New Arrivals</h3>
              </span>
              <span>
                {/* <h3 className="prod-heading-2 ps-auto">View More</h3> */}
              </span>
            </div>
            <hr />
          </div>
          {products.length > 0 ? (
            <>
              {renderProductRow(products.slice(0, 4))}
            </>
          ) : (
            <div>No products available</div>
          )}
        </div>
      </section>
    </>
  );
};

export default Productslide;
