import React, { useEffect, useState } from 'react';
import Header from '../../componenth/header/Header';
import Footer from '../../componenth/header/Footer';
import { FaStar } from "react-icons/fa";
import { REACT_BASE_PATH, Asset_url } from "../../api";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import product1 from "../../img/product5.jpeg";
import { toast } from 'react-toastify';


const AllOrders = ({ }) => {
  const [orders, setOrders] = useState([])
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  if (!accessToken || !userId) {
    navigate("/signin");
  }

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return
    }
    const fetchAllOrders = async () => {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/listOrdersByUserId`,
          {
            userId: userId
          }
        );
        console.log(response.data)
        setOrders(response.data.data)
      } catch (e) {
        toast.error(e.response?.data?.message || "Some error occured while fetching orders, please try again");
      }
    }
    fetchAllOrders()
  }, [])
  return (
    <>
      <div className="all-orders">
        <div className='container'>
          <div className="allorders-header d-flex justify-content-between mb-5">
            <span>Home &gt; Cart</span>
            <input type="text" placeholder="Search Orders" className="order-search" />
          </div>
          <div className="orders-page">
            <div className="filters">
              <div className="filter-category">
                <h4>ORDER STATUS</h4>
                <ul>
                  <li><input type="checkbox" /> On the way</li>
                  <li><input type="checkbox" /> Delivered</li>
                  <li><input type="checkbox" /> Cancelled</li>
                  <li><input type="checkbox" /> Returned</li>
                </ul>
              </div>
              <div className="filter-category">
                <h4>ORDER TIME</h4>
                <ul>
                  <li><input type="checkbox" /> Last 30 days</li>
                  <li><input type="checkbox" /> 2023</li>
                  <li><input type="checkbox" /> 2022</li>
                  <li><input type="checkbox" /> 2021</li>
                  <li><input type="checkbox" /> 2020</li>
                  <li><input type="checkbox" /> Older</li>
                </ul>
              </div>
            </div>
            <div className="orders">
              <div className="order-list">
                {[...orders].reverse().map((order) => (
                  <div key={order._id} className="cart-item" style={{ position: 'relative' }} onClick={() => navigate(`/order-details/${order._id}`)}>
                    <div className='product-image d-flex flex-column justify-content-center align-items-center'>
                      <img
                        src={order.items[0]?.productId.product_banner_image?.startsWith('http') ? order.items[0]?.productId.product_banner_image : `${Asset_url}${order.items[0]?.productId.product_banner_image}`}
                        alt='order-item-image'
                        className="img-fluid"
                      />
                      {order.items.length > 1 ? <div className='more-items-div'>{order.items.length - 1} more items</div> : ''}
                    </div>
                    <div className="cart-product-details d-flex justify-content-between align-items-center pe-5">
                      <div>
                        {order.items.length > 1 ?
                          <h4>{order.items[0]?.productId.product_name.length>40 ? order.items[0]?.productId.product_name.slice(0,40) + "..." : order.items[0]?.productId.product_name } and {order.items.length - 1} more</h4>
                          :
                          <h4>{order.items[0]?.productId.product_name.length>40 ? order.items[0]?.productId.product_name.slice(0,40) + "..." : order.items[0]?.productId.product_name}</h4>
                        }

                        <div className="product-price">Payment Method:
                          <span className="cart-current-price mb-0"> {order.paymentMethod} ({order.paymentStatus})</span>
                        </div>
                      </div>
                      <div className=''>
                        <div className="delivery-info d-flex gap-3">
                          Order Status: {order.status}
                        </div>
                        {order.status==='delivered' && <div className='rate-and-review-product text-primary'>
                          <FaStar /> Rate & Review Product
                        </div>}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrders;
