import React, { useEffect } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';

const Dashboard = () => {
  const [layout, setLayout] = React.useState('vertical');
  const [radius, setRadius] = React.useState(10);
  const [itemNb, setItemNb] = React.useState(5);
  const dataset = [
    [10, -13, 'Jan'],
    [17, -19, 'Feb'],
    [10, -20, 'Mar'],
    [16, -11, 'Apr'],
    [12, -7, 'May'],
    [7, -10, 'Jun'],
    [16, 20, 'Jul'],
    [-19, -12, 'Aug'],
    [10, -13, 'Spe'],
    [17, -19, 'Oct'],
    [10, -20, 'Nov'],
    [16, -11, 'Dec'],
  ].map(([high, low, order]) => ({
    high,
    low,
    order,
  }));
  const chartSettingsH = {
    dataset,
    height: 420,
    yAxis: [{ scaleType: 'band', dataKey: 'order' }],
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
    slotProps: {
      legend: {
        direction: 'row',
        position: { vertical: 'bottom', horizontal: 'middle' },
        padding: -5,
      },
    },
  };
  const chartSettingsV = {
    ...chartSettingsH,
    xAxis: [{ scaleType: 'band', dataKey: 'order' }],
    yAxis: undefined,
  };

  const desktopOS = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const mobileOS = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const platforms = [
    {
      label: 'Feshion',
      value: 72.72,
    },
    {
      label: 'Decor',
      value: 16.38,
    },
    {
      label: 'Electronics',
      value: 3.83,
    },
    {
      label: 'Sports',
      value: 2.42,
    },
  ];

  const normalize = (v, v2) => Number.parseFloat(((v * v2) / 100).toFixed(2));

  const mobileAndDesktopOS = [
    ...mobileOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Mobile)' : v.label,
      value: normalize(v.value, platforms[0].value),
    })),
    ...desktopOS.map((v) => ({
      ...v,
      label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
      value: normalize(v.value, platforms[1].value),
    })),
  ];

  const valueFormatter = (item) => `${item.value}%`;

  return (
    <>
      <section id="admin-main-content" className="mb-3" >
        <div className="container-fuild">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Dashboard</h5>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Sales</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>276k</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Orders</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>12,206k</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Revenue</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>$300k</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3 leftb">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Payments</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>$53253k</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-8">
            <section className="custom-cart-panel mb-3">
              <div className="container-fuild">
                <div className="row">
                  <div className="vendor-top col-12">
                    <h5 className="title-v">Total Revenue</h5>
                  </div>
                </div>
              </div>
              <BarChart
                series={[
                  { dataKey: 'high', label: '2024', layout, stack: 'stack', color: '#3b1a61' },
                  { dataKey: 'low', label: '2023', layout, stack: 'stack', color: '#822457' },
                ]}
                {...(layout === 'vertical' ? chartSettingsV : chartSettingsH)}
                borderRadius={radius}
              />
              {/* <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </section>
          </div>
          <div className="col-md-4">
            <section className="custom-cart-panel mb-3">
              <PieChart
                height={200}
                slotProps={{
                  legend: { labelStyle: { fontSize: 11 }, itemMarkHeight: 10, itemMarkWidth: 10 },
                }}
                series={[
                  {
                    data: mobileAndDesktopOS.slice(0, itemNb),
                    innerRadius: 65,
                    arcLabel: () => '',
                    arcLabelMinAngle: 20,
                    valueFormatter,
                  },
                ]}
              />
            </section>
            <section className="custom-cart-panel mb-3">
              <PieChart
                height={200}
                slotProps={{
                  legend: { labelStyle: { fontSize: 11 }, itemMarkHeight: 10, itemMarkWidth: 10 },
                }}
                series={[
                  {
                    data: mobileAndDesktopOS.slice(0, itemNb),
                    innerRadius: 65,
                    arcLabel: () => '',
                    arcLabelMinAngle: 20,
                    valueFormatter,
                  },
                ]}
              />
            </section>
          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">All Product</h5>
              </div>
              {/* <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                    area: true,
                    color: '#822457', // Line color
                    areaColor: '#3b1a61',
                  },
                ]}
                width={500}
                height={300}
              />
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">All Orders</h5>
              </div>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [3, 7.5, 4, 9.5, 2.5, 9],
                    area: true,
                    color: '#822457', // Line color
                    areaColor: '#3b1a61',
                  },
                ]}
                width={500}
                height={300}
              />
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">Orders Listing</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">Product Listing</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  );
};

export default Dashboard;
