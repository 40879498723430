import React from 'react'
import HeroSection from './HeroSection'
import Footer from '../../../componenth/header/Footer'
import './commonotherlink.css'
import Content from './Content'
import Header from './Header'

const CommonOtherLinkComponent = ({contentArray, heroSectionItems, middleBannerContent, title}) => {
  return (
    <>
      <div className='krizaar-pay'>
        <Header/>
        <HeroSection heroSectionItems={heroSectionItems} />
        <Content contentArray={contentArray} title={title} middleBannerContent={middleBannerContent} />
        <Footer/>
      </div>
    </>
  )
}

export default CommonOtherLinkComponent
