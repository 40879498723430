import React, { useEffect, useState } from "react";
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from "../WebPanel/PrivacyPolicy";
import ReturnRefund from "../WebPanel/ReturnRefund";
import About from "../WebPanel/About";
import SingleProduct from "../WebPanel/SingleProduct";
import FAQPage from "../WebPanel/faq/FAQPage";
import Vsignup from "../../componenth/common/Vsignup";
import ProfileInfo from "../WebPanel/ProfileInfo";
import ManageAddress from "../WebPanel/ManageAddress";
import EditAddress from "../WebPanel/EditAddress";
import Payment from "../WebPanel/Payment";
import Whishlist from "../WebPanel/Whishlist";
import Notification from "../WebPanel/Notification";
import Signup from "../../componenth/common/Signup";
import Signin from "../../componenth/common/Signin";
import ContactUs from "../WebPanel/ContactUs";
import Resetpassword from "../../componenth/common/Resetpassword";
import Vendorsignup from "../../componenth/common/Vendorsignup";
import Forgotpassword from "../../componenth/common/Forgotpassword";
import VendorLogin from "../../componenth/common/VendorLogin";
import AffilateSignup from "../../componenth/common/AffilateSignup";
import Home from "../WebPanel/Home";
import Category from "../WebPanel/category/Category";
import SubCategory from "../WebPanel/category/SubCategory";
import OTPVerification from "../../componenth/common/OTPVerification";
import Cart from "../WebPanel/cart/Cart";
import { CartProvider } from "../WebPanel/cart/CartContext";
import Checkout from "../WebPanel/checkout/Checkout";
import { CheckoutProvider } from "../WebPanel/checkout/CheckoutContext";
import AllOrders from "../WebPanel/Allorder";
import OrderDetails from "../WebPanel/OrderDetails";
import ReturnOrder from "../WebPanel/ReturnOrder";
import { GoogleOAuthProvider } from "@react-oauth/google";
import VendorRegistration from "../../home/VendorRegistration";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import ChildCategory from "../WebPanel/category/ChildCategory";
import { ThirdwebProvider } from "thirdweb/react";
import { optimism } from "thirdweb/chains";
import KrizaarPay from "../WebPanel/KrizaarPay";
import SellersClub from "../WebPanel/SellersClub";
import KrizaDrops from "../WebPanel/KrizaDrops";
import AdJunction from "../WebPanel/AdJunction";
import KrizaarAffluence from "../WebPanel/KrizaarAffluence";
import Logistics from "../WebPanel/Logistics";
import KrizaarCompliances from "../WebPanel/KrizaarCompliances";
import Stake from "../WebPanel/swap_stake/Stake";
import Swap from "../WebPanel/swap_stake/Swap";
import NotFound from "../../componenth/common/NotFound";
import TermsOfService from "../WebPanel/TermsOfService";
import CookiesPolicy from "../WebPanel/CookiesPolicy";
import InterestBasedAds from "../WebPanel/InterestBasedAds";
import SanctionsPolicy from "../WebPanel/SanctionsPolicy";
import ElectronicsCommunicationPolicy from "../WebPanel/ElectronicsCommunicationPolicy";
import AffilateLogin from "../../componenth/common/AffilateLogin";
import AffilateDashboard from "../WebPanel/AffilateDashboard";
import PayPalPaymentgateway from "../WebPanel/checkout/PaypalPaymentGateway";
import Footer from "../../componenth/header/Footer";
import Header from "../../componenth/header/Header";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spreedly from "../WebPanel/checkout/Spreedly";
import NMIPaymentGateway from "../WebPanel/checkout/NMIPaymentGateway";

const clientId = process.env.REACT_APP_CLIENT_ID;

const WebPanelRoutes = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);  // New state to control blur effect

    useEffect(() => {
        const loadStripePromise = async () => {
            const stripeprom = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)
            setStripePromise(stripeprom)
        }
        loadStripePromise()
    }, []);

    return (
        <ThirdwebProvider clientId={clientId} supportedChains={[optimism]}>
            <Elements stripe={stripePromise}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <CheckoutProvider>
                        <CartProvider>
                            <ToastContainer />
                            <Header setIsDropdownOpen={setIsDropdownOpen} />

                            <div className={isDropdownOpen ? 'blur-background' : ''}>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/affliateDashboard" element={<AffilateDashboard />} />
                                    <Route path="/userprofile" element={<ProfileInfo />} />
                                    <Route path="/manageaddress" element={<ManageAddress />} />
                                    <Route path="/add-edit-address/:addressId" element={<EditAddress />} />
                                    <Route path="/allorders" element={<AllOrders />} />
                                    <Route path="/order-details/:orderId" element={<OrderDetails />} />
                                    <Route path="/payment" element={<Payment />} />
                                    <Route path="/whishlist" element={<Whishlist />} />
                                    <Route path="/notification" element={<Notification />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                    <Route path="/cookies-policy" element={<CookiesPolicy />} />
                                    <Route path="/sanctions-policy" element={<SanctionsPolicy />} />
                                    <Route path="/interest-based-ads" element={<InterestBasedAds />} />
                                    <Route path="/terms-of-service" element={<TermsOfService />} />
                                    <Route path="/electronic-communication-policy" element={<ElectronicsCommunicationPolicy />} />
                                    <Route path="/checkout" element={<Checkout />} />
                                    <Route path="/return-order/:orderId" element={<ReturnOrder />} />
                                    <Route path="/return-refund" element={<ReturnRefund />} />
                                    <Route path="/singleproduct/:productId" element={<SingleProduct />} />
                                    <Route path="/category/:categoryId" element={<Category />} />
                                    <Route path="/subcategory/:categoryId" element={<SubCategory />} />
                                    <Route path="/childcategory/:categoryId" element={<ChildCategory />} />
                                    <Route path="/cart" element={<Cart />} />
                                    <Route path="/signup" element={<Signup />} />
                                    <Route path="/signin" element={<Signin />} />
                                    <Route path="/otp-verification" element={<OTPVerification />} />
                                    <Route path="/contactus" element={<ContactUs />} />
                                    <Route path="/forgot-password" element={<Forgotpassword />} />
                                    <Route path="/reset-password/:token" element={<Resetpassword />} />
                                    <Route path="/vendorsignup" element={<Vendorsignup />} />
                                    <Route path="/vendorlogin" element={<VendorLogin />} />
                                    <Route path="/affilate_register" element={<AffilateSignup />} />
                                    <Route path="/affiliate_login" element={<AffilateLogin />} />
                                    <Route path="/admin-login" element={<VendorLogin />} />
                                    <Route path="/vendor-registration" element={<VendorRegistration />} />
                                    <Route path="/about-us" element={<About />} />
                                    <Route path="/vsignup" element={<Vsignup />} />
                                    <Route path="/faqpage" element={<FAQPage />} />
                                    <Route path="/pay" element={<KrizaarPay />} />
                                    <Route path="/sellersclub" element={<SellersClub />} />
                                    <Route path="/krizadrops" element={<KrizaDrops />} />
                                    <Route path="/adjunction" element={<AdJunction />} />
                                    <Route path="/affluence" element={<KrizaarAffluence />} />
                                    <Route path="/compliance" element={<KrizaarCompliances />} />
                                    <Route path="/logistics" element={<Logistics />} />
                                    <Route path="/swap" element={<Swap />} />
                                    <Route path="/stake" element={<Stake />} />
                                    <Route path="/stake" element={<Stake />} />
                                    <Route path="*" element={<NotFound />} />
                                    {/* <Route path="/test" element={<Spreedly />} /> */}
                                    {/* <Route path="/test2" element={<NMIPaymentGateway />} /> */}
                                </Routes>
                                <Footer />
                            </div>
                        </CartProvider>
                    </CheckoutProvider>
                </GoogleOAuthProvider>
            </Elements>
        </ThirdwebProvider>
    );
};

export default WebPanelRoutes;
